<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="py-2">
      <b-overlay
      :show = "this.loading"
      spinner-variant="primary"
      rounded="sm"
      >

      <!-- Login v1 -->
      <b-card class="mb-0 login-box" style="max-width: 400px;">
        <b-link class="brand-logo">
          <img src="@/assets/images/logo/logo.png" alt="login" class="mx-auto">
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('LoginTitle') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('LoginMessage') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $t('password') }}</label>
                <b-link  href="https://shop.restock.gr/?forgot-password=true" target="_blank">
                  <small class='forgot-password'>{{ $t('ForgotPassword') }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="checkbox_remember_me"
                name="checkbox-1"
              >
                {{ $t('RememberMe') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              {{buttonText}}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
            <span>{{ $t('Do you want to become a supplier?') }} </span>
            <b-link href="https://www.restock.gr/contact/" target="_blank">
              <span class='forgot-password'>&nbsp;{{ $t('Contact us') }} </span>
            </b-link>
          </b-card-text>

      </b-card>
      <!-- /Login v1 -->
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BSpinner, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import { required, email } from '/src/validation.js'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { supplierAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BSpinner,
    BOverlay,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '', 
      loading: false,
      // validation rules
      required,
      email,
      checkbox_remember_me: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    buttonText(){
      return this.loading  ? "Φόρτωση..." : "Σύνδεση Χρήστη" 
    }
  },
  methods: {
    login() {

      this.loading = true

      this.$refs.loginForm.validate()
      .then(success => {
        if (success) {
          const payload = {
            checkbox_remember_me: this.checkbox_remember_me,
            userDetails: {
              email: this.userEmail,
              password: this.password
            }
          }

          this.$store.dispatch('auth/loginJWT', payload)
          .then(() => { 
            if (this.checkbox_remember_me) {
              this.$cookies.set('fromLogin', 1, null, null, null, true, 'Strict')
            }
            this.$router.push({path: '/'})
          })
          .catch(error => {
             this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t(error.message),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
              })
              this.loading=false         
          })         
        }      
      }) 
    }
    ,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.forgot-password {
  color: #00bcd4;
}
</style>